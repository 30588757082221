const colors = [
  "#000000",
  "#417505",
  "#4A4A4A",
  "#4A90E2",
  "#50E3C2",
  "#7ED321",
  "#8B572A",
  "#9013FE",
  "#9B9B9B",
  "#B8E986",
  "#BD10E0",
  "#D0021B",
  "#F5A623",
  "#F8E71C",
  "#FFFFFF",
];
export default colors;
