import React from "react";
import PropTypes from "prop-types";
import OptionTypes from "../types/OptionsTypes";

export default function Flip({ options, updateOptions }) {
  return (
    <label htmlFor="flip" className="flex flex-row justify-start items-center">
      <input
        type="checkbox"
        defaultChecked={options.flip}
        onChange={() => {
          updateOptions({ flip: !options.flip });
        }}
        id="flip"
        className="cursor-pointer border-0 rounded-lg"
      />
      <span className="label-text">Flip Text</span>
    </label>
  );
}
Flip.propTypes = {
  updateOptions: PropTypes.func.isRequired,
  options: OptionTypes.isRequired,
};
