import React from "react";
import PropTypes from "prop-types";
import Drawer from "@mui/material/Drawer";
import useIsMobile from "../hooks/useIsMobile";
import { SettingsIcon } from "./Icons";

export default function Advanced({ children }) {
  const [show, setShow] = React.useState(false);
  const isMobile = useIsMobile();
  return (
    <div>
      <button
        type="button"
        onClick={() => {
          setShow(!show);
        }}
        className="w-24 h-10 rounded-lg flex justify-center items-center hover:scale-110 ease-linear duration-100 bg-white"
      >
        <SettingsIcon />
      </button>
      <Drawer
        open={show}
        onClose={() => {
          setShow(false);
        }}
        anchor={isMobile ? "bottom" : "right"}
      >
        <div className="p-5 flex flex-col gap-6 sidebar-width">{children}</div>
      </Drawer>
    </div>
  );
}
Advanced.propTypes = {
  children: PropTypes.node.isRequired,
};
