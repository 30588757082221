import { PropTypes } from 'prop-types';

const OptionTypes = PropTypes.shape({
  angle: PropTypes.number,
  avatar: PropTypes.string,
  colorAngle: PropTypes.number,
  endColor: PropTypes.shape({
    a: PropTypes.number,
    b: PropTypes.number,
    g: PropTypes.number,
    r: PropTypes.number,
  }),
  flip: PropTypes.bool,
  kerning: PropTypes.number,
  size: PropTypes.number,
  startColor: PropTypes.shape({
    a: PropTypes.number,
    b: PropTypes.number,
    g: PropTypes.number,
    r: PropTypes.number,
  }),
  text: PropTypes.string,
});

export default OptionTypes;
