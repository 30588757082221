import React from "react";
import PropTypes from "prop-types";

export default function Range({
  label,
  name,
  value,
  onChange,
  min,
  max,
  step,
}) {
  return (
    <label
      htmlFor={name}
      className="flex flex-col justify-start items-stretch min-w-[200px]"
    >
      <span className="label-text">{label}</span>
      <input
        type="range"
        value={value}
        onChange={(evt) => {
          onChange({ [name]: parseInt(evt.target.value, 10) });
        }}
        id={name}
        min={min}
        max={max}
        step={step}
        className="flex-1"
      />
    </label>
  );
}
Range.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  step: PropTypes.number.isRequired,
};
