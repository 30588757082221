import React from "react";
import PropTypes from "prop-types";
import OptionTypes from "../types/OptionsTypes";

export default function TextInput({ options, updateOptions }) {
  return (
    <input
      type="text"
      value={options.text}
      onChange={(evt) => {
        updateOptions({ text: evt.target.value });
      }}
      className="text-input"
      placeholder="status text for avatar"
    />
  );
}
TextInput.propTypes = {
  updateOptions: PropTypes.func.isRequired,
  options: OptionTypes.isRequired,
};
