/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import HowToUse from "./HowToUse";

function Header() {
  return (
    <div className="w-full py-5 px-10 flex flex-row items-center justify-between">
      <a
        href="https://twitter.com/intent/user?screen_name=bansal_io"
        target="_blank"
        className="hover:scale-150 duration-300 ease-in rotate"
      >
        <img
          src="/images/bansal.png"
          alt="Avatar"
          className="w-8 h-8 rounded-full duration-300"
        />
      </a>
      {/* <span
        className="text-lg font-semibold text-violet-600/80 text-center flex-grow"
      >
        Apply status text to your avatar
      </span> */}
      <HowToUse />
    </div>
  );
}

export default Header;
