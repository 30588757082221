/* eslint-disable no-param-reassign */
function generateGradient(canvas, context, options) {
  const { startColor, endColor } = options;
  const colorAngle = parseInt(options.colorAngle, 10);
  let angle = [500, 0, 0, 0];
  switch (colorAngle) {
    case 0:
      angle = [500, 0, 0, 0];
      break;
    case 1:
      angle = [0, 500, 0, 0];
      break;
    case 2:
      angle = [0, 0, 500, 0];
      break;
    case 3:
      angle = [0, 0, 0, 500];
      break;
    case 4:
      angle = [250, 250, 0, 0];
      break;
    case 5:
      angle = [0, 250, 250, 0];
      break;
    case 6:
      angle = [0, 0, 250, 250];
      break;
    case 7:
      angle = [250, 0, 0, 250];
      break;
    default:
      angle = [0, 0, 0, 500];
      break;
  }
  const gradientColor = context.createLinearGradient(
    angle[0],
    angle[1],
    angle[2],
    angle[3]
  );
  gradientColor.addColorStop(
    0,
    `rgba(${startColor.r}, ${startColor.g}, ${startColor.b}, ${startColor.a})`
  );
  gradientColor.addColorStop(
    1,
    `rgba(${endColor.r}, ${endColor.g}, ${endColor.b}, ${endColor.a})`
  );
  return gradientColor;
}

function getCircularText(
  text,
  diameter,
  startAngle,
  align,
  textInside,
  inwardFacing,
  fName,
  fSize,
  kerning
) {
  // text:         The text to be displayed in circular fashion
  // diameter:     The diameter of the circle around which the text will
  //               be displayed (inside or outside)
  // startAngle:   In degrees, Where the text will be shown. 0 degrees
  //               if the top of the circle
  // align:        Positions text to left right or center of startAngle
  // textInside:   true to show inside the diameter. False to show outside
  // inwardFacing: true for base of text facing inward. false for outward
  // fName:        name of font family. Make sure it is loaded
  // fSize:        size of font family. Don't forget to include units
  // kearning:     0 for normal gap between letters. positive or
  //               negative number to expand/compact gap in pixels
  //------------------------------------------------------------------------

  // declare and intialize canvas, reference, and useful variables
  align = align.toLowerCase();
  const mainCanvas = document.createElement("canvas");
  const ctxRef = mainCanvas.getContext("2d");
  const clockwise = align === "right" ? 1 : -1; // draw clockwise for aligned right. Else Anticlockwise
  startAngle *= Math.PI / 180; // convert to radians

  // calculate height of the font. Many ways to do this
  // you can replace with your own!
  const div = document.createElement("div");
  div.innerHTML = text;
  div.style.position = "absolute";
  div.style.top = "-10000px";
  div.style.left = "-10000px";
  div.style.fontFamily = fName;
  div.style.fontSize = fSize;
  document.body.appendChild(div);
  const textHeight = div.offsetHeight;
  document.body.removeChild(div);

  // in cases where we are drawing outside diameter,
  // expand diameter to handle it
  if (!textInside) diameter += textHeight * 2;

  mainCanvas.width = diameter;
  mainCanvas.height = diameter;
  // omit next line for transparent background
  mainCanvas.style.backgroundColor = "#000000";
  ctxRef.fillStyle = "white";
  ctxRef.font = `${fSize} ${fName}`;

  // Reverse letters for align Left inward, align right outward
  // and align center inward.
  if (
    (["left", "center"].indexOf(align) > -1 && inwardFacing) ||
    (align === "right" && !inwardFacing)
  )
    text = text.split("").reverse().join("");

  // Setup letters and positioning
  ctxRef.translate(diameter / 2, diameter / 2); // Move to center
  startAngle += Math.PI * !inwardFacing; // Rotate 180 if outward
  ctxRef.textBaseline = "middle"; // Ensure we draw in exact center
  ctxRef.textAlign = "center"; // Ensure we draw in exact center

  // rotate 50% of total angle for center alignment
  if (align === "center") {
    for (let j = 0; j < text.length; j += 1) {
      const charWid = ctxRef.measureText(text[j]).width;
      startAngle +=
        ((charWid + (j === text.length - 1 ? 0 : kerning)) /
          (diameter / 2 - textHeight) /
          2) *
        -clockwise;
    }
  }

  // Phew... now rotate into final start position
  ctxRef.rotate(startAngle);

  // Now for the fun bit: draw, rotate, and repeat
  for (let j = 0; j < text.length; j += 1) {
    // half letter
    const charWid = ctxRef.measureText(text[j]).width;

    // rotate half letter
    ctxRef.rotate((charWid / 2 / (diameter / 2 - textHeight)) * clockwise);

    // draw the character at "top" or "bottom"
    // depending on inward or outward facing
    ctxRef.fillText(
      text[j],
      0,
      (inwardFacing ? 1 : -1) * (0 - diameter / 2 + textHeight / 2)
    );

    // rotate half letter
    ctxRef.rotate(
      ((charWid / 2 + kerning) / (diameter / 2 - textHeight)) * clockwise
    );
  }

  // Return it
  return mainCanvas;
}

function drawText(canvas, context, options) {
  const circularText = getCircularText(
    options.text,
    500,
    parseInt(options.angle, 10),
    "center",
    true,
    !!options.flip,
    "Arial",
    `${options.size * 0.55}pt`,
    parseInt(options.kerning, 10) // kerning
  );

  const image = new Image();
  image.id = "pic";
  image.src = circularText.toDataURL();
  image.setAttribute("crossOrigin", "anonymous");
  image.onload = () => {
    const margin = 10;
    context.drawImage(
      image,
      -margin,
      -margin,
      canvas.width + 2 * margin,
      canvas.height + 2 * margin
    );
  };
}

function drawCircle(canvas, context, options) {
  const width = options.size;
  const radius = (canvas.width - width) / 2;
  context.beginPath();
  context.arc(canvas.width / 2, canvas.height / 2, radius, 0, 2 * Math.PI);
  context.strokeStyle = generateGradient(canvas, context, options);
  context.lineWidth = width;
  context.stroke();
  drawText(canvas, context, options);
}

function drawRect(canvas, context) {
  context.beginPath();
  context.rect(0, 0, canvas.width, canvas.height);
  context.fillStyle = "white";
  context.fill();
}

function drawImage(canvas, context, options) {
  const img = new Image();
  img.setAttribute("crossOrigin", "anonymous");
  img.onload = () => {
    drawRect(canvas, context);
    const hRatio = canvas.width / img.width;
    const vRatio = canvas.height / img.height;
    const ratio = Math.max(hRatio, vRatio);
    const centerShiftX = (canvas.width - img.width * ratio) / 2;
    const centerShiftY = (canvas.height - img.height * ratio) / 2;
    context.drawImage(
      img,
      0,
      0,
      img.width,
      img.height,
      centerShiftX,
      centerShiftY,
      img.width * ratio,
      img.height * ratio
    );
    drawCircle(canvas, context, options);
  };
  img.src = options.avatar;
}

export default drawImage;
