import React from "react";

export default function Hire() {
  return (
    <a
      href="https://twitter.com/bansal_io"
      traget="_blank"
      className="fixed bottom-5 left-5"
    >
      <img
        alt="Hire Me"
        src="https://bansal.github.io/hire-me.png"
        className="h-12 hover:scale-110 duration-100 ease-in"
      />
    </a>
  );
}
