import React from "react";
import PropTypes from "prop-types";
import { UploadIcon } from "./Icons";
import useIsMobile from "../hooks/useIsMobile";

export default function UploadAvatar({ updateOptions }) {
  const isMobile = useIsMobile();

  const changeHandler = (e) => {
    const reader = new FileReader();
    reader.onload = (event) => {
      updateOptions({ avatar: event.target.result });
    };

    try {
      reader.readAsDataURL(e.target.files[0]);
    } catch (error) {
      // eslint-disable-next-line no-alert
      alert("Upload Image first");
    }
  };

  return (
    <label
      htmlFor="avatar"
      className={
        isMobile
          ? "button button-upload button-flat"
          : "group button button-upload"
      }
    >
      <input
        type="file"
        id="avatar"
        accept="image/*"
        className="hidden"
        onChange={changeHandler}
      />
      <UploadIcon className="w-6 h-6 text-white/80 group-hover:text-white" />
      Upload Pic
    </label>
  );
}
UploadAvatar.propTypes = {
  updateOptions: PropTypes.func.isRequired,
};
