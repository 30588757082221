import React from "react";
import Dialog from "@mui/material/Dialog";

import Tweet from "./Tweet";
import useIsMobile from "../hooks/useIsMobile";
import { DownloadIcon } from "./Icons";

export default function Download(props) {
  const [open, setOpen] = React.useState(false);
  const isMobile = useIsMobile();

  const download = () => {
    const image = document.getElementById("canvas").toDataURL("image/jpg", 1);
    const a = document.createElement("a");
    a.href = image;
    a.download = "avatar.jpg";
    a.click();
    setOpen(true);
  };

  return (
    <div className="block w-full">
      <Dialog onClose={() => setOpen(false)} open={open}>
        <div className="p-5 bg-stone-100 w-[300px] md:w-[500px]">
          <Tweet text={props.text} />
        </div>
      </Dialog>

      <button
        type="button"
        onClick={download}
        className={
          isMobile
            ? "button button-download button-flat"
            : "group button button-download"
        }
      >
        <span>Download</span>
        <DownloadIcon className="w-6 h-6 text-white/80 group-hover:text-white" />
      </button>
    </div>
  );
}
