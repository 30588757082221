import React from "react";

function Preset({ num, onSelect }) {
  return (
    <div
      onClick={() => {
        onSelect(num);
      }}
      className="bg-white shadow-md p-1 rounded-full hover:scale-110 duration-100 ease-linear cursor-pointer"
    >
      <img
        src={`/images/presets/${num}.jpg`}
        alt={`Sample ${num + 1}`}
        className="block bg-white w-16 h-16 rounded-full"
      />
    </div>
  );
}
export default Preset;
