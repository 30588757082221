import React, { useState } from "react";
import Canvas from "./components/Canvas";
import Tweet from "./components/Tweet";
// import Footer from './components/Footer';
import presets from "./data/presets";
import Header from "./components/Header";
import UploadAvatar from "./components/UploadAvatar";
import Flip from "./components/Flip";
import Download from "./components/Download";
import TextInput from "./components/TextInput";
import Advanced from "./components/Advanced";
import StatusPicker from "./components/StatusPicker";
import Range from "./components/Range";
import ColorPicker from "./components/ColorPicker";
import useIsMobile from "./hooks/useIsMobile";
import Hire from "./components/Hire";

function App() {
  const [options, setOptions] = useState(presets[0]);
  const isMobile = useIsMobile();

  const updateOptions = (newOption) => {
    setOptions({ ...options, ...newOption });
  };

  const applyPreset = (num) => {
    setOptions({ ...presets[num], avatar: options.avatar });
  };

  return (
    <div className="w-screen min-h-screen bg-stone-100 flex flex-col">
      <Header />
      <div className="flex flex-col lg:flex-row flex-grow">
        <div className="lg:w-1/2 flex justify-center items-center">
          <Canvas options={options} />
        </div>
        <div className="lg:w-1/2 h-full overflow-auto flex justify-center items-center">
          <div>
            <div className="text-xs uppercase font-semibold text-violet-500 text-center md:text-left mt-5">
              Add Status To Your Avatar
            </div>
            <StatusPicker avatar={options.avatar} applyPreset={applyPreset} />
            <div className="flex flex-col justify-start gap-5 p-5 rounded-lg bg-stone-200/70 overflow-y-auto">
              {isMobile || <UploadAvatar updateOptions={updateOptions} />}
              <TextInput options={options} updateOptions={updateOptions} />
              <div className="flex flex-row justify-between items-center">
                <ColorPicker
                  value={options.startColor}
                  onChange={(color) => {
                    updateOptions({ startColor: color });
                  }}
                />
                <ColorPicker
                  value={options.endColor}
                  onChange={(color) => {
                    updateOptions({ endColor: color });
                  }}
                />
                <Advanced>
                  <Range
                    name="colorAngle"
                    label="Color Angle"
                    value={options.colorAngle}
                    min={0}
                    max={7}
                    step={1}
                    onChange={updateOptions}
                  />
                  <Range
                    name="size"
                    label="Size"
                    value={options.size}
                    min={20}
                    max={100}
                    step={5}
                    onChange={updateOptions}
                  />
                  <Range
                    name="angle"
                    label="Text Angle"
                    value={options.angle}
                    min={0}
                    max={360}
                    step={15}
                    onChange={updateOptions}
                  />
                  <Range
                    name="kerning"
                    label="Text Spaceing"
                    value={options.kerning}
                    min={-10}
                    max={20}
                    step={1}
                    onChange={updateOptions}
                  />
                  <Flip options={options} updateOptions={updateOptions} />
                </Advanced>
              </div>
              {isMobile || <Download text={options.text} />}
            </div>
            <div className="p-5 text-stone-400 text-sm">
              <em>Note: </em>
              This app works offline and we don&#39;t upload your picture on
              server.
            </div>
            {/* <Tweet text={options.text} /> */}
            {isMobile && (
              <div className="fixed flex flex-row z-10 left-0 right-0 bottom-0">
                <UploadAvatar updateOptions={updateOptions} />
                <Download text={options.text} />
              </div>
            )}
          </div>
        </div>
      </div>
      {isMobile || <Hire />}
      {/* <Footer /> */}
    </div>
  );
}

export default App;
