import React from "react";
import PropTypes from "prop-types";
import { RightArrowIcon } from "./Icons";
import Preset from "./Preset";
import presets from "../data/presets";

export default function StatusPicker({ avatar, applyPreset }) {
  return (
    <div className="flex flex-row justify-around gap-5 my-5">
      <div className="bg-white shadow-md p-1 rounded-full">
        <img
          src={avatar}
          alt="avatar"
          className="block bg-white w-16 h-16 rounded-full object-cover"
        />
      </div>
      <div className="text-3xl flex justify-center items-center">
        <RightArrowIcon className="w-8 h-8" />
      </div>
      {[...presets.keys()].map((num) => (
        <Preset num={num} onSelect={applyPreset} key={num} />
      ))}
    </div>
  );
}
StatusPicker.propTypes = {
  applyPreset: PropTypes.func.isRequired,
  avatar: PropTypes.string.isRequired,
};
