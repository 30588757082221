/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useEffect } from "react";
import { RgbaColorPicker } from "react-colorful";
import Drawer from "@mui/material/Drawer";
import tinycolor from "tinycolor2";
import useIsMobile from "../hooks/useIsMobile";
import { DropperIcon } from "./Icons";
import colors from "../data/colors";

// eslint-disable-next-line react/prop-types
function ColorPicker({ value, onChange }) {
  const [show, setShow] = useState(false);
  const [color, setColor] = useState(value);
  const isMobile = useIsMobile();

  useEffect(() => {
    setColor(value);
  }, [value]);
  // console.log(presetColors);
  // console.log(window.screen.width, window.screen.height);
  const handleClick = () => {
    setShow(!show);
  };

  const handleClose = () => {
    setShow(false);
  };

  const setCustomColor = (hex) => {
    const rgb = tinycolor(hex).toRgb();
    setColor({ ...rgb, a: 1 });
    onChange({ ...rgb, a: 1 });
  };

  const colorChange = (pickedColor) => {
    setColor({ ...pickedColor });
    onChange(pickedColor);
  };

  return (
    <div>
      <div
        className="inline-block rounded-lg shadow cursor-pointer pattern-checks-sm bg-white text-stone-300"
        onClick={handleClick}
      >
        <div
          className="w-24 h-10 rounded-lg flex justify-center items-center hover:scale-110 ease-linear duration-100"
          style={{
            backgroundColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
          }}
        >
          <DropperIcon className="text-black" />
        </div>
      </div>
      <Drawer
        open={show}
        onClose={handleClose}
        anchor={isMobile ? "bottom" : "right"}
      >
        <div className="p-5 sidebar-width">
          <RgbaColorPicker color={color} onChange={colorChange} />
          <div className="flex flex-row flex-wrap gap-1 my-6">
            {colors.map((c) => (
              <button
                key={c}
                type="button"
                aria-label="Color"
                className="w-6 h-6 rounded hover:scale-110"
                style={{ backgroundColor: c }}
                onClick={() => setCustomColor(c)}
              />
            ))}
          </div>
        </div>
      </Drawer>
    </div>
  );
}
export default ColorPicker;
