import React, { useEffect, useRef } from "react";
import OptionTypes from "../types/OptionsTypes";

import drawImage from "./utils";

function Canvas(props) {
  const { options } = props;
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    drawImage(canvas, context, options);
  }, [options]);

  return (
    <canvas
      width="500"
      height="500"
      className="bg-white rounded-full w-[300px] md:scale-75 lg:scale-100 lg:fixed top-1/4"
      ref={canvasRef}
      id="canvas"
    />
  );
}

Canvas.propTypes = {
  options: OptionTypes.isRequired,
};

export default Canvas;
