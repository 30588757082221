import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

function Tweet({ text }) {
  const [tweet, setTweet] = useState("");
  const host = window.location.host;
  useEffect(() => {
    setTweet(
      `Hey! Check my new #StatusOnDP \n ${text} \n \n Made with → ${host}`
    );
  }, [text]);

  const share = () => {
    if (navigator.share) {
      navigator.share({
        text: tweet,
        url: `https://${host}`,
      });
    }
  };

  return (
    <div className="w-full text-center">
      <h3>Help me to grow</h3>
      <form
        action="https://twitter.com/intent/tweet"
        method="get"
        target="_blank"
        className="flex flex-col gap-3 mt-5"
      >
        <textarea
          name="text"
          rows="3"
          className="text-input text-xs"
          value={tweet}
          onChange={(e) => {
            setTweet(e.target.value);
          }}
        />
        {navigator.share ? (
          <button type="button" className="button button-tweet" onClick={share}>
            Share
          </button>
        ) : (
          <button type="submit" className="button button-tweet">
            Tweet
          </button>
        )}
      </form>
    </div>
  );
}

Tweet.propTypes = {
  text: PropTypes.string.isRequired,
};

export default Tweet;
