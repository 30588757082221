import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
// import useIsMobile from '../hooks/useIsMobile';

function HowToUse() {
  const [open, setOpen] = useState(false);
  // const isMobile = useIsMobile();

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(true)}
        className="text-xs uppercase text-stone-600 font-semibold underline hover:text-violet-500"
      >
        How to use?
      </button>
      <Drawer
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        anchor="bottom"
      >
        <div className="p-5 md:p-20 text-stone-600 text-sm">
          <strong>Create</strong>
          <ol>
            <li>1. Upload Your Avatar</li>
            <li>
              2. Select Preset
              <span className="opacity-80"> (optional) </span>
            </li>
            <li>3. Change Status Text</li>
            <li>4. Download</li>
          </ol>
          <br />
          <strong>Share</strong>
          <p>
            You can set this avatar to any social media platform where you are
            active Like
            <span className="text-blue-500"> Twitter, Github, Dribble </span>
            or any other social platform.
          </p>
          <br />
          <strong>Support Me</strong>
          <p>
            If you like the project, please share it with your friends or tweet
            it.
          </p>
        </div>
      </Drawer>
    </>
  );
}

export default HowToUse;
