const presets = [
  {
    angle: 225,
    avatar: "/images/demo.jpg",
    colorAngle: 5,
    endColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 0.1,
    },
    flip: false,
    kerning: -6,
    size: 80,
    startColor: {
      r: 146,
      g: 38,
      b: 233,
      a: 1,
    },
    text: "#HIRING",
  },
  {
    angle: 180,
    avatar: "/images/demo.jpg",
    colorAngle: 5,
    endColor: {
      r: 37,
      g: 235,
      b: 196,
      a: 1,
    },
    flip: false,
    kerning: -1,
    size: 70,
    startColor: {
      r: 233,
      g: 89,
      b: 38,
      a: 1,
    },
    text: "Making StatusOnDP",
  },
  {
    angle: 225,
    avatar: "/images/demo.jpg",
    colorAngle: 5,
    endColor: {
      r: 255,
      g: 255,
      b: 255,
      a: 0.1,
    },
    flip: false,
    kerning: -4,
    size: 80,
    startColor: {
      r: 80,
      g: 129,
      b: 26,
      a: 1,
    },
    text: "#HIREME",
  },
];
export default presets;
